export enum DonationAction {
    TOGGLE_MENU = 'TOGGLE_MENU',
    CREATE_DONATION = 'CREATE_DONATION',
    UPDATE_TYPE = 'UPDATE_TYPE',
    FORM_STEP = 'FORM_STEP',
    SELECT_DONATION_TYPE = 'SELECT_DONATION_TYPE',
    CHECK_CUSTOMER_INFO = 'CHECK_CUSTOMER_INFO',
    CLOSE_MODAL = 'CLOSE_MODAL'
}

export enum DonationType {
    ACCEPT_DONATIONS = 'ACCEPT_DONATIONS',
    SELL_A_PRODUCT = 'SELL_A_PRODUCT',
    SELL_CURRENCY = 'SELL_CURRENCY',
    CART = 'CART'
}

export enum STEP_DONATION_FROM {
    SELECT_DONATION_TYPE = 'SELECT_DONATION_TYPE',
    CUSTOMER_INFO = 'CUSTOMER_INFO',
    DONATION_FORM_DETAIL = 'DONATION_FORM_DETAIL'
}

export enum CustomerInfoField {
    NOT_COLLECT = 'chkNotCollect',
    FULL_NAME = 'chkFullname',
    EMAIL = 'chkEmail'
}

export enum CheckoutImageType {
    UPLOAD = 1,
    URL = 2
}
