import { memo, useMemo } from 'react';

// material-ui
import { Box, Stack, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import { useDispatch, useSelector } from 'store';
import { drawerWidth } from 'store/constant';
import { openDrawer } from 'store/slices/menu';
import MiniDrawerStyled from '../Drawer/MiniDrawerStyled';
import LogoSection from '../LogoSection';
import MenuList from './MenuList';

// ==============================|| SIDEBAR DRAWER ||============================== //

interface SidebarProps {
    window?: Window;
}

const Sidebar = ({ window }: SidebarProps) => {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);
    const logo = useMemo(
        () => (
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <Box sx={{ display: 'flex', p: 2, mx: 'auto' }}>
                    <LogoSection />
                </Box>
            </Box>
        ),
        []
    );

    // const drawer = useMemo(
    //     () => (
    //         <PerfectScrollbar
    //             component="div"
    //             style={{
    //                 height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)',
    //                 paddingLeft: '16px',
    //                 paddingRight: '16px'
    //             }}
    //         >
    //             <MenuList />
    //             {/* <MenuCard /> */}
    //             <Stack direction="row" justifyContent="center" sx={{ mb: 2 }}>
    //                 <Typography variant="h5" textAlign="center">
    //                     © 2023{' '}
    //                     <a
    //                         href="http://www.xaigate.com"
    //                         target="_blank"
    //                         rel="noreferrer"
    //                         style={{ color: theme.palette.primary.dark, textDecoration: 'none' }}
    //                     >
    //                         XAIGate
    //                     </a>
    //                     {'. '}
    //                     All copy rights are reserved.
    //                 </Typography>
    //             </Stack>
    //         </PerfectScrollbar>
    //     ),
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    //     [matchUpMd]
    // );

    return (
        <Box component="nav" sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }} aria-label="mailbox folders">
            {/* <Drawer
                container={container}
                variant={matchUpMd ? 'persistent' : 'temporary'}
                anchor="left"
                open={drawerOpen}
                onClose={() => dispatch(openDrawer(!drawerOpen))}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        background: theme.palette.background.default,
                        color: theme.palette.text.primary,
                        borderRight: 'none',
                        [theme.breakpoints.up('md')]: {
                            top: '88px'
                        }
                    }
                }}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                {drawerOpen && logo}
                {drawerOpen && drawer}
            </Drawer> */}
            <MiniDrawerStyled
                variant="permanent"
                open={drawerOpen}
                // container={container}
                // variant={matchUpMd ? 'persistent' : 'temporary'}
                // anchor="left"
                // open={drawerOpen}
                onClose={() => dispatch(openDrawer(!drawerOpen))}
                sx={{
                    '& .MuiDrawer-paper': {
                        // width: drawerWidth,
                        background: theme.palette.background.default,
                        color: theme.palette.text.primary,
                        borderRight: 'none',
                        [theme.breakpoints.up('md')]: {
                            top: '88px'
                        },
                        ...(drawerOpen && {
                            height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)',
                            paddingLeft: '16px',
                            paddingRight: '16px'
                        })
                    }
                }}
                // ModalProps={{ keepMounted: true }}
                // color="inherit"
            >
                {drawerOpen && logo}
                <PerfectScrollbar component="div">
                    <MenuList />
                    {/* <MenuCard /> */}
                    <Stack direction="row" justifyContent="center" sx={{ mb: 2 }}>
                        <Typography variant="h5" textAlign="center">
                            © 2023{' '}
                            <a
                                href="http://www.xaigate.com"
                                target="_blank"
                                rel="noreferrer"
                                style={{ color: theme.palette.primary.dark, textDecoration: 'none' }}
                            >
                                XAIGate
                            </a>
                            {'. '}
                            All copy rights are reserved.
                        </Typography>
                    </Stack>
                </PerfectScrollbar>
            </MiniDrawerStyled>
        </Box>
    );
};

export default memo(Sidebar);
