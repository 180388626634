import { PRIVATE_ROUTES_PATH } from './route-paths';

export const addSlashToPath = (route: string): string => {
    return `/${route}`;
};

export const routeFeatures = {
    walletDetail: (symbol: string) => {
        return addSlashToPath(
            `${PRIVATE_ROUTES_PATH.MERCHANT}/${PRIVATE_ROUTES_PATH.WALLET}/${PRIVATE_ROUTES_PATH.WALLET_DETAIL}/${symbol}`
        );
    },
    checkout: () => {
        return addSlashToPath(PRIVATE_ROUTES_PATH.CHECKOUT);
    },
    dashboard: () => {
        return addSlashToPath(PRIVATE_ROUTES_PATH.DASHBOARD);
    },
    donation: () => {
        return addSlashToPath(PRIVATE_ROUTES_PATH.DONATION_PAGE);
    }
};
